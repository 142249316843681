import {CanDeactivateFn} from '@angular/router';
import {AuthService} from "../../xstudioz/services/auth.service";
import {inject} from "@angular/core";
import {MessageService} from "primeng/api";

export const sellerGuard: CanDeactivateFn<unknown> = (component, currentRoute, currentState, nextState) => {
  let authService = inject(AuthService);
  let messageService = inject(MessageService);
  if (authService.seller()) {
    messageService.add({
      summary: 'Alert',
      detail: 'You are not authorized to view this link',
      severity: 'error'
    });
  }
  return !(authService.seller());
};
