import {Component} from '@angular/core';
import {ControlErrorAnchorDirective, ControlErrorsDirective, FormActionDirective} from "@ngneat/error-tailor";
import {PaginatorModule} from "primeng/paginator";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ApiService} from "../../../xstudioz/services/api.service";
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {NgEventBus} from "ng-event-bus";
import {finalize} from "rxjs";
import {CalendarModule} from "primeng/calendar";
import {matchValidator} from "../../../xstudioz/validators/match-password";
import {ageValidator} from "../../../xstudioz/validators/age-18";
import {KeyFilterModule} from "primeng/keyfilter";
import {RadioButtonModule} from "primeng/radiobutton";
import {JsonPipe, NgForOf} from "@angular/common";
import {MultiSelectModule} from "primeng/multiselect";
import {LoadingBtnComponent} from "../../../xstudioz/components/loading-btn.component";
import {ConfirmationService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {RegsitrationSuccessComponent} from "../regsitration-success/regsitration-success.component";
import {SHOW_LOGIN} from "../../../app.events";
import {FileUploadEvent, FileUploadModule} from "primeng/fileupload";


@Component({
  selector: 'app-register-dialog',
  standalone: true,
  imports: [
    ControlErrorsDirective,
    FormActionDirective,
    PaginatorModule,
    ReactiveFormsModule,
    CalendarModule,
    KeyFilterModule,
    RadioButtonModule,
    NgForOf,
    JsonPipe,
    MultiSelectModule,
    ControlErrorAnchorDirective,
    LoadingBtnComponent,
    ConfirmDialogModule,
    FileUploadModule
  ],
  templateUrl: './register-dialog.component.html',
  styleUrl: './register-dialog.component.scss'
})
export class RegisterDialogComponent {
  public loading = false;
  public referer: any;
  public refLoading = false;
  public shopCategories = [];
  private controls: any = {
    referred_by: new FormControl(''),
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ]),
    mobile: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirm_password: new FormControl('', [Validators.required,]),
    role: new FormControl('', [Validators.required]),
  };
  public registerForm: FormGroup;
  dob: any;
  public user;

  constructor(
    public api: ApiService,
    public eventBus: NgEventBus,
    public confirmationService: ConfirmationService,
    public dynamicDialogRef: DynamicDialogRef,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
  ) {

    if (config.data?.role === 'seller') {
      this.getShopCategories();
      this.controls.shop_categories = new FormControl('', [Validators.required]);
      this.controls.business_type = new FormControl('', [Validators.required]);
      this.controls.business_name = new FormControl('', [Validators.required]);
      this.controls.form_number = new FormControl('', [Validators.required]);
      this.controls.declaration = new FormControl('', [Validators.required]);
    } else {
      // this.controls.date_of_birth = new FormControl<any>(null, [Validators.required, ageValidator(18)]);
    }

    this.registerForm = new FormGroup(this.controls, [matchValidator('password', 'confirm_password')])
    this.registerForm.patchValue({
      role: config.data?.role ?? 'customer',
      referred_by: config.data?.ref ?? null
    });
    console.log('Form data is ', this.registerForm.value)
    if (this.registerForm.value.referred_by) {
      console.log(this.registerForm.value.referred_by);
      this.checkUser();
    }
  }

  checkUnique(field: string) {
    this.api.post('check-if-unique?r=' + this.config.data?.role, {field, value: this.registerForm.get(field).value})
      .subscribe((res: any) => {
        this.registerForm.get(field).setErrors(res.used ? {exists: true} : null, {emitEvent: true})
        this.registerForm.get(field).markAsDirty({onlySelf: true})
        this.registerForm.get(field).markAsTouched({onlySelf: true})
      })
  }

  getShopCategories() {
    this.api.post('get-shop-categories', {})
      .subscribe((res: any) => {
        this.shopCategories = res.map((category: { id: number, name: string }) => {
          return {
            label: category.name,
            value: category.id
          }
        })
      })
  }

  doRegister() {
    if (this.registerForm.invalid) {
      console.log(this.registerForm);
      return;
    }
    this.loading = true;
    this.api.post('register', this.registerForm.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (res) => {
          this.dynamicDialogRef.close();
          this.user = res;
          this.dialogService.open(RegsitrationSuccessComponent, {header: 'Registration Successful', data: {user: this.user}})
        },
        error: (res) => {
          if (res.status == 422) {
            this.registerForm.get('referred_by')?.setErrors({invalid: true});
            for (const errKey in res.error.errors) {

            }
          }
        }
      })
  }


  loginNow() {
    this.dynamicDialogRef.close();
    this.eventBus.cast(SHOW_LOGIN);
  }

  checkUser() {
    if (this.registerForm.value.referred_by == '') {
      this.referer = null;
      return;
    }
    this.refLoading = true;
    this.referer = null;
    this.api.post('user-by-unique-id', {unique_id: this.registerForm.value.referred_by})
      .pipe(finalize(() => this.refLoading = false))
      .subscribe({
        next: (res) => {
          this.referer = res;
        },
        error: (err) => {
          this.referer = 0;
          this.registerForm.controls['referred_by'].setErrors({invalid: true});
        }
      })
  }


  convertDateStringToDate(dateString: string): Date | null {
    if (dateString == null) return null;
    // Define month names for parsing
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Split the date string into day, month, and year
    const parts = dateString.split(' ');
    if (parts.length !== 3) {
      console.error('Invalid date format');
      return null;
    }

    const day = parseInt(parts[0], 10);
    const month = monthNames.indexOf(parts[1]);
    const year = parseInt(parts[2], 10);

    // Check if parsing was successful
    if (isNaN(day) || isNaN(month) || isNaN(year)) {
      console.error('Invalid date format');
      return null;
    }

    // Create a new Date object
    return new Date(year, month, day);
  }

  calculateAge($event) {
    let dateObj = this.convertDateStringToDate(this.dob);
    let control = this.registerForm.controls['date_of_birth'];
    this.registerForm.patchValue({date_of_birth: this.dob})
    let timeDiff = Math.abs(Date.now() - dateObj?.getTime());
    let age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
    if (age < 18) {
      control?.setErrors({ageGreaterThan18: true}, {emitEvent: true});
      control.markAsTouched({onlySelf: true});
    }
  }

  typeUpdated() {
    if (['Both', 'Offline'].indexOf(this.registerForm.controls['business_type'].value) > -1) {
      this.registerForm.addControl('admin_discount', new FormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]));
    } else {
      this.registerForm.removeControl('admin_discount');
    }
    console.log(this.registerForm.value);
  }

  protected readonly onclick = onclick;

  onUpload($event: FileUploadEvent) {
    let src = $event.originalEvent['body'].file;
    this.registerForm.patchValue({
      declaration: src
    })
  }
}
