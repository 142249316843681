<div class="p-8 max-w-[400px] w-full">
  <p>Thank you for signing up. Your registration is complete.</p>
  <div class="mt-4 xs-data-table">
    <table>
      <tr>
        <td>Name</td>
        <td>{{ user.name }}</td>
      </tr>
      @if (user.business_name) {
        <tr>
          <td>Business Name</td>
          <td>{{ user.business_name }}</td>
        </tr>
      }
      <tr>
        <td>Unique ID</td>
        <td>{{ user.unique_id }}</td>
      </tr>
      <tr>
        <td>Password</td>
        <td>{{ user.password }}</td>
      </tr>
      <tr>
        <td>Email</td>
        <td>{{ user.email }}</td>
      </tr>
      <tr>
        <td>Mobile</td>
        <td>{{ user.mobile }}</td>
      </tr>
    </table>
  </div>
</div>
