<div class="bg-primary fixed left-0 top-0 w-full  h-[121px] z-[10]">
  <app-top-bar></app-top-bar>
  <div class="container py-4" [ngClass]="{'container':!fullWidth}">
    <div class="flex justify-between items-center px-4  gap-8">
      <div class="shrink-0 flex gap-4 items-center w-[100px] md:w-[200px]">
        <a class="text-white lg:hidden" (click)="openMenu()"><i class="fa-solid fa-bars" [ngClass]="{'fa-bars':!menuOpened,'fa-times':menuOpened}"></i></a>
        <div><img class="w-[110px]" src="assets/logo.png" alt=""></div>
      </div>
      <app-search class="hidden md:block grow max-w-[600px]"></app-search>
      <app-header-menu></app-header-menu>
    </div>
  </div>
  <div class="bg-primary py-1 pb-2">
    <div class="md:hidden mx-3 ">
      <app-search class="grow max-w-[600px]"></app-search>
    </div>
  </div>
  @if (!isAdmin) {
    <div class="main-menu">
      <div class="container ">
        <ul>
          <li><a routerLink="/">Home</a></li>
          <li><a routerLink="/categories">Categories</a></li>
          <li><a routerLink="/shop">Shop</a></li>
        </ul>
      </div>
    </div>
  }
</div>

<div class="mobile-footer-menu">
  <ul>
    <li>
      <a href="sdsa"><i class="fa fa-home"></i>Home</a>
    </li>
    <li>
      <a href="sdsa"><i class=" fa fa-dashboard"></i>Categories</a>
    </li>
    <li>
      <a href="sdsa"><i class="fa fa-user"></i>Account</a>
    </li>
  </ul>
</div>
