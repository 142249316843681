import {Component} from '@angular/core';
import {ControlErrorAnchorDirective, ControlErrorsDirective, errorTailorImports, FormActionDirective} from "@ngneat/error-tailor";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoadingBtnComponent} from "../../../xstudioz/components/loading-btn.component";
import {NgEventBus} from "ng-event-bus";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ApiService} from "../../../xstudioz/services/api.service";
import {finalize} from "rxjs";
import {matchValidator} from "../../../xstudioz/validators/match-password";
import {ConfirmationService} from "primeng/api";

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ControlErrorsDirective,
    FormActionDirective,
    FormsModule,
    LoadingBtnComponent,
    ReactiveFormsModule,
    ControlErrorAnchorDirective,
    errorTailorImports,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  public forgotPasswordForm = new FormGroup({
    'unique_id': new FormControl('', [Validators.required]),
  })
  public newPassword = new FormGroup({
    'token': new FormControl('', [Validators.required]),
    'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
    'confirm_password': new FormControl('', [Validators.required]),
  }, [
    matchValidator('password', 'confirm_password')
  ])
  public error = null;
  public loading = false;
  public reset = false;

  public resetToken = null;

  constructor(
    public api: ApiService,
    public eventBus: NgEventBus,
    public confirmationService: ConfirmationService,
    public dynamicDialogRef: DynamicDialogRef,
    public dynamicDialogConfig: DynamicDialogConfig,
  ) {
    if (dynamicDialogConfig.data.token) {
      this.resetToken = dynamicDialogConfig.data.token;
      this.newPassword.patchValue({
        token: this.resetToken
      })
    }
  }

  showLogin() {
    this.dynamicDialogRef.close();
    this.eventBus.cast('app:show-login')
  }

  doReset(form) {
    this.reset = false;
    this.error = false;
    if (this.forgotPasswordForm.invalid) return;
    this.loading = true;
    this.api.post('reset-password', this.forgotPasswordForm.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (res: any) => {
          form.reset();
          this.reset = true;
        },
        error: (e) => {
          this.error = e.message;
        }
      })
  }

  register() {

  }

  setNewPassword() {
    this.reset = false;
    this.error = false;
    if (this.newPassword.invalid) {
      console.log(this.newPassword.errors);
      return
    }

    this.loading = true;
    this.api.post('set-new-password', this.newPassword.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (res: any) => {
          this.dynamicDialogRef.close();
          this.confirmationService.confirm({
            header: 'Password Updated',
            message: 'You password has been updated. You can use your new password to login now',
            accept: () => {
            }
          })
        }, error: (e) => {
          this.error = e.message;
        }
      })
  }
}
