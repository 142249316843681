<div class="py-2 bg-black text-gray-200 text-sm">
  <div class="container flex justify-between">
    <ul class="flex gap-2 *:text-white top-bar-links">
      <li>
        <a routerLink="page/about-us">About Us</a>
      </li>
      <li>
        <a routerLink="/contact-us">Contact Us</a>
      </li>
      <li>
        <a class="text-gray-300 pr-4" (click)="openVendorRegister()">Become a Seller</a>
      </li>
    </ul>
    <div class="hidden md:block">Welcome {{ authService.user?.business_name ?? authService.user?.name }} <span class="text-green-600 text-sm">({{ authService.user?.unique_id }})</span></div>
  </div>
</div>
