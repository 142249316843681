import {Component, Input} from '@angular/core';
import {DialogService} from "primeng/dynamicdialog";
import {LoginDialogComponent} from "../dialogs/login-dialog/login-dialog.component";
import {NgEventBus} from "ng-event-bus";
import {NgClass} from "@angular/common";
import {AuthService} from "../../xstudioz/services/auth.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {LocalStorage} from "ngx-webstorage";
import {ImagePreviewComponent} from "../image-preview/image-preview.component";
import {SearchComponent} from "./search/search.component";
import {HeaderMenuComponent} from "./header-menu/header-menu.component";
import {TopBarComponent} from "./top-bar/top-bar.component";

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    ImagePreviewComponent,
    SearchComponent,
    HeaderMenuComponent,
    TopBarComponent
  ],
  providers: [DialogService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() fullWidth = false;
  @LocalStorage(null, false) menuOpened;
  public isAdmin = false;

  constructor(
    public dialogService: DialogService,
    public eventBus: NgEventBus,
    public authService: AuthService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
  ) {
    this.isAdmin = this.router.url.indexOf('dashboard') > -1
  }


  openMenu() {
    this.menuOpened = !this.menuOpened;
  }


}
