import {Component, inject} from '@angular/core';
import {AuthService} from "../../../xstudioz/services/auth.service";
import {DialogService} from "primeng/dynamicdialog";
import {RegisterDialogComponent} from "../../dialogs/register-dialog/register-dialog.component";
import {SellerEnquiryComponent} from "../seller-enquiry/seller-enquiry.component";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ClientOnly} from "../../../xstudioz/decorators/run-on-client";

@Component({
  selector: 'app-top-bar',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './top-bar.component.html',
  styleUrl: './top-bar.component.scss'
})
export class TopBarComponent {
  public authService = inject(AuthService);
  public dialogService = inject(DialogService);
  public activatedRoute = inject(ActivatedRoute);

  constructor() {
    this.open();
  }

  @ClientOnly()
  open() {
    this.activatedRoute.queryParams.subscribe(res => {
      if (res['register'] == 'seller') this.openVendorRegister();
    })
  }

  openVendorRegister() {
    this.dialogService.open(SellerEnquiryComponent, {
      header: 'Become a seller',
      showHeader: false,
      data: {role: 'seller'},
      styleClass: 'login-form'
    })
  }
}
