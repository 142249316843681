import {Component, input} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {errorTailorImports, FormActionDirective} from "@ngneat/error-tailor";
import {ButtonModule} from "primeng/button";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ApiService} from "../../../../xstudioz/services/api.service";
import {AuthService} from "../../../../xstudioz/services/auth.service";
import {NgEventBus} from "ng-event-bus";
import {LoadingBtnComponent} from "../../../../xstudioz/components/loading-btn.component";
import {finalize} from "rxjs";
import {Router} from "@angular/router";
import {USER_LOGIN} from "../../../../app.events";

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    errorTailorImports,
    ButtonModule,
    LoadingBtnComponent,
  ],
  template: `
    <div class="flex gap-4 items-center">
      <div class="max-w-[400px] p-8">
        <img class="w-48" src="assets/logo-red.png" alt="">
        <h3 class="mt-8">Welcome to <span class="text-red-500">Coromin infotech</span> <br>and network private limited
        </h3>
      </div>
      <form class="grow p-8 bg-white" [formGroup]="loginForm" errorTailor (ngSubmit)="doLogin()">
        <h2 class="mb-8 flex justify-between items-center">Account Login <a class="cursor-pointer" (click)="dynamicDialogRef.close()"><i class="fa text-gray-500 fa-times"></i></a></h2>
        @if (error) {
          <div class="alert alert-danger"><i class="fa mr-2 fa-times-circle"></i>{{ error }}</div>
        }
        <div class="form-group">
          <label for="" class="form-label">Unique ID</label>
          <input type="text" formControlName="unique_id" class="form-control uppercase">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Password</label>
          <input type="password" formControlName="password" class="form-control">
        </div>
        <app-loading-btn [loading]="loading">Login</app-loading-btn>

        <div class="mt-8">
          <a (click)="forgotPassword()" class="py-2 block cursor-pointer hover:text-gray-800">Forgot Password</a>
          <a (click)="register()" class="py-2 block cursor-pointer hover:text-gray-800">Dont have an account? <span
            class="text-green-800 font-bold">Register Now</span></a>
        </div>
      </form>
    </div>
  `,
  styles: ``
})
export class LoginFormComponent {
  public redirect = input();

  public loginForm = new FormGroup({
    unique_id: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  })

  constructor(
    public dynamicDialogRef: DynamicDialogRef,
    public api: ApiService,
    public eventBus: NgEventBus,
    public authService: AuthService,
    public router: Router,
    public dynamicDialogConfig: DynamicDialogConfig
  ) {
  }

  public loading = false;
  public error = null;

  doLogin() {
    this.error = null;
    if (this.loginForm.invalid) return
    this.loading = true;
    this.api.post('/api/login', this.loginForm.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: res => {
          this.authService.setUser(res)
          this.dynamicDialogRef.close();
          this.eventBus.cast(USER_LOGIN);
          if (this.dynamicDialogConfig.data['redirect'] === 'NO') return;
          if (this.authService.seller() || this.authService.admin()) {
            this.router.navigateByUrl('/dashboard');
          }

        }, error: (e) => {
          this.error = e.message;
        }
      })
  }

  register() {
    this.dynamicDialogRef.close();
    this.eventBus.cast('app:show-register')
  }

  forgotPassword() {
    this.dynamicDialogRef.close();
    this.eventBus.cast('app:show-forgot-password');
  }
}
