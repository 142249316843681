<div class="py-4 bg-gray-50 prose max-w-none">
  <div class="grid">
    <h4></h4>
  </div>
  <div class="container text-xs text-gray-600 leading-6">

    <div>
      <h3>Other Links</h3>
      <div class="flex other-links">
        <a [routerLink]="['/page','about-us']" href="">About Us</a>
        <a [routerLink]="['/page','sell-with-us']" href="">Sell With Us</a>
        <a [routerLink]="['/page','return-policy']" href="">Return Policy</a>
        <a [routerLink]="['/page','privacy-policy']" href="">Privacy Policy</a>
        <a [routerLink]="['/page','terms-conditions']" href="">Terms & Conditions</a>
      </div>
    </div>
  </div>
</div>
<div class="bg-gray-100 text-gray-400 text-xs py-3">
  <div class="text-center">Total Visits : {{ count }}</div>
  <div class="container">
    Copyright 2024 Tabdeal.in, All Rights Reserved
  </div>
</div>
