<div class="relative">
  <input (focus)="focused=true" (blur)="defocus()" type="text" class="outline-0 text-sm rounded-lg bg-white  placeholder-gray-400 w-full px-4 py-3" placeholder="Start typing to search" (input)="onInput( $event) ">
  @if (loading) {
    <i class="fa fa-spinner animate-spin absolute right-4 top-1/2 -translate-y-1/2"></i>
  } @else {
    <i class="fa fa-search absolute right-4 top-1/2 -translate-y-1/2"></i>
  }
  @if (focused) {
    <div class="absolute w-full bg-white rounded mt-2 shadow-2xl shadow-gray-200 p-4">
      @if (searchResult.length == 0) {
        @if (noItemsFound) {
          <div class="text-center">No results found</div>
        } @else {
          <div class="text-center">Start typing to see search results</div>
        }
      } @else {
        @for (r of searchResult; track r.id) {
          <a [routerLink]="['/shop/product', r.slug]" class="flex py-2 gap-4 hover:text-primary">
            <div class="shrink-0">
              <app-resized-image [src]="r.image_gallery[0]" [width]="40" aspectRatio="1-1"></app-resized-image>
            </div>
            <div>
              {{ r.name }}
              <app-price-tag [showDiscount]="false" [salePrice]="r.sale_price" [price]="r.price" currency="INR"></app-price-tag>
            </div>
          </a>
        }

      }
    </div>
  }
</div>
