import {Directive, ElementRef, Input, Renderer2, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from "../xstudioz/services/auth.service";

@Directive({
  selector: '[appUpgraded]',
  standalone: true
})


export class UpgradedDirective {

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private authService: AuthService // Inject your authentication service
  ) {
  }

  public hasView = false;

  @Input() set appUpgraded(condition: boolean) {
    if (!condition) {
      if (this.authService.upgraded()) {
        this.hasView = false;
        this.viewContainerRef.clear();
        return;
      } else {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      if (!this.authService.upgraded()) {
        this.hasView = false;
        this.viewContainerRef.clear();
        return;
      } else {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    }
  }
}
