<a (click)="sideCartVisible=true">
  <span class="relative">
     <img src="assets/cart.svg" class="size-6" alt="">
    <span class="absolute -top-1 -right-1 rounded-full h-4 px-1  text-xs bg-yellow-400 text-black flex items-center justify-center  font-bold">{{ cartService.totalCartItems }}</span>
  </span>
  Cart
</a>
<p-sidebar appendTo="body" [(visible)]="sideCartVisible" position="right" styleClass="w-full max-w-[600px] prose p-4" [blockScroll]="true">
  <ng-template pTemplate="header">Cart</ng-template>
  @for (item of cartService.cart?.items; track item) {
    <div class="flex gap-4 py-6 border-b border-b-gray-100 items-center" *ngIf="item.product">
      <div class="relative">
        <a (click)="remove(item.shop_product_id)" class="absolute -left-2 -top-2 size-6 flex items-center justify-center rounded-full bg-red-500 p-1 no-underline text-white"><i class="fa fa-times "></i></a>

        @if (item.product.image_gallery && item.product.image_gallery.length > 0) {
          <app-resized-image classList="rounded shadow" [src]="item.product.image_gallery[0]" [width]="60" aspectRatio="1-1"></app-resized-image>
        }
      </div>
      <div class="grow">
        <div class="flex items-center justify-between">
          <div class="">
            <div class="mb-2 text-black font-bold grow">{{ item.product.name }}</div>
            <app-price-tag classList="-mt-2" [price]="item.product.price" [showDiscount]="false" currency="INR" [salePrice]="item.product.sale_price"></app-price-tag>
            <app-variation-data [variationData]="item.variation_data"></app-variation-data>
            @if (item.cashback_amount > 0) {
              <div class="text-green-700">Additional Cashback : <strong>{{ item.cashback_amount|currency:'INR' }}</strong></div>
            }
          </div>
          <div class="inline-block mt-3">
            <app-counter (updated)="updateQty(item, $event)" [qty]="item.quantity"></app-counter>
          </div>
        </div>
      </div>
    </div>
  } @empty {
    <div class="text-center bg-blue-50 p-8 rounded">Your cart is empty</div>
  }
  <ng-template pTemplate="footer">
    @if (cartService.cart.shipping_fee == 0) {
      <div class="rounded bg-green-50 border border-dotted border-green-500 mb-2 p-4 text-sm text-green-700">
        Your order qualifies for free shipping!
      </div>
    }
    <div class="flex justify-between prose mb-4 my-3">
      <h3>Subtotal</h3>
      <h2 class="text-primary">{{ cartService.cartTotal |currency:'INR' }}</h2>
    </div>
    <div class="flex gap-4 mt-6 not-prose">
      <!--      <a routerLink="shop/checkout" class="grow border bg-white border-gray-200 hover:border-primary hover:text-primary transition-all block text-black py-4 text-center rounded">View Cart</a>-->
      <a (click)="navToCheckout()" routerLink="/shop/checkout" class="grow bg-primary hover:bg-primary/80 transition-all block py-4 text-center rounded text-white">Checkout</a>
    </div>
  </ng-template>
</p-sidebar>
