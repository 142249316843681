<div class="container">
  <h1>Contact Us</h1>
  @if (contact) {
    <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <div class="bxo">
        <h4>Reach US</h4>
        <div [innerHTML]="contact[0].address"></div>
      </div>
      <div class="bxo">
        <h4>Call Us</h4>
        <i class="fa fa-phone mr-4"></i> {{ contact[0].phone }}

        <h4 class="mt-8">Write To Us</h4>
        <i class="fa fa-envelope mr-4"></i> {{ contact[0].email }}
      </div>
      <div class="bxo">
        <h4>FOLLOW US</h4>
        <div class="social flex gap-4 text-2xl">
          @for (s of social; track s) {
            @if (contact[0].social[s].length > 0) {
              <a class="hover:text-primary" [href]="contact[0].social[s]"> <i class="fab" [ngClass]="'fa-'+s"></i></a>
            }
          }
        </div>
      </div>
      <div></div>
    </div>
  }
</div>
