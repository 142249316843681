import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http';
import {catchError, from, map, Observable, of, throwError} from "rxjs";
import {Messages} from "primeng/messages";
import {MessageService} from "primeng/api";
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";
import {ClientOnly} from "../decorators/run-on-client";


export const ToastInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const messageService = inject(MessageService);
  const authService = inject(AuthService);

  return next(req).pipe(
    map(event => {
      if (event instanceof HttpResponse) {
        if (event.status == 200 && event.body['toast'] && event.body['toast'] == true && event.body['message']) {
          console.log(event.body);
          messageService.add({
            detail: event.body['message'],
            severity: 'success'
          })
        }
      }
      return event;
    }),
    catchError((err: any, t) => {
      // @ts-ignore
      if (1 == 2 && err instanceof HttpErrorResponse) {

        if (err.status == 401) {
          authService.logout();
          messageService.add({
            id: 'session-expired',
            summary: 'Session Expired',
            detail: 'You have been logged out.Please Login Again',
            severity: 'error'
          })
        }
        let error = err.error;
        if (error && error['toast'] && err.error['message']) {
          messageService.add({
            detail: err.error['message'],
            severity: 'error'
          })
        }
      }

      return throwError(() => err.error);
    }),
  )
    ;
};
