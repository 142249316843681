import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoadingBtnComponent} from "../../../xstudioz/components/loading-btn.component";
import {ApiService} from "../../../xstudioz/services/api.service";
import {finalize} from "rxjs";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {errorTailorImports, FormActionDirective} from "@ngneat/error-tailor";

@Component({
  selector: 'app-seller-enquiry',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    LoadingBtnComponent,
    FormActionDirective,
    errorTailorImports
  ],
  templateUrl: './seller-enquiry.component.html',
  styleUrl: './seller-enquiry.component.scss'
})
export class SellerEnquiryComponent {
  public sellerEnquiryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    business_name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    message: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
  })
  public loading = false;
  public submitted = false;
  public api = inject(ApiService)
  public dynamicDialogRef = inject(DynamicDialogRef)

  submit() {
    this.loading = true;
    this.api.post('seller-enquiry', this.sellerEnquiryForm.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe((res: any) => {
        this.submitted = true
      })
  }
}
