<form [formGroup]="form" errorTailor (submit)="submit()">
  <div class="form-group">
    <label for="" class="form-label">Password</label>
    <input type="text" formControlName="password" class="form-control">
  </div>
  <div class="form-group">
    <label for="" class="form-label">Confirm Password</label>
    <input type="text" formControlName="confirm_password" class="form-control">
  </div>
  <div class="form-group">
    <label for="" class="form-label flex justify-between">OTP
      <app-send-otp formControlName="otp" [purpose]="'trxPassword'"></app-send-otp>
    </label>
    <input type="text" formControlName="otp" class="form-control">
  </div>
  <app-loading-btn [loading]="loading">Set Affiliate Password</app-loading-btn>
</form>
