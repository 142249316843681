import {Component, Input} from '@angular/core';
import {DynamicDialogConfig} from "primeng/dynamicdialog";

@Component({
  selector: 'app-regsitration-success',
  standalone: true,
  imports: [],
  templateUrl: './regsitration-success.component.html',
  styleUrl: './regsitration-success.component.scss'
})
export class RegsitrationSuccessComponent {
  public user = null;

  constructor(public dynamicDialogConfig: DynamicDialogConfig) {
    this.user = this.dynamicDialogConfig.data.user;
    console.log(this.dynamicDialogConfig.data);
  }
}
