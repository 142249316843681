import {Component, inject} from '@angular/core';
import {distinctUntilChanged, finalize, from, Subject, switchMap} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {ApiService} from "../../../xstudioz/services/api.service";
import {DebouncePipe} from "../../../xstudioz/pipes/debounce.pipe";
import {PriceTagComponent} from "../../../front-end/shop-page/price-tag/price-tag.component";
import {ResizedImageComponent} from "../../../xstudioz/components/resized-image.component";
import {RouterLink} from "@angular/router";

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    DebouncePipe,
    PriceTagComponent,
    ResizedImageComponent,
    RouterLink
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent {
  public focused = false;
  public noItemsFound = false;
  public searchResult = [];
  public apiService = inject(ApiService);


  private searchSubject: Subject<string> = new Subject<string>();

  constructor() {
    this.searchSubject.pipe(
      debounceTime(300), // Adjust debounce time as needed
      distinctUntilChanged()
    ).subscribe((searchTerm: string) => {
      this.search(searchTerm);
    });
  }

  onInput(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchSubject.next(inputValue);
  }

  public loading = false;

  search(searchTerm): void {
    this.loading = true;
    this.apiService
      .post('search', {q: searchTerm})
      .pipe(finalize(() => this.loading = false)).subscribe((res: any) => {
      this.searchResult = res;
    })
  }

  defocus() {
    setTimeout(() => {
      this.focused = false;
    }, 500);
  }
}
