<div class="flex gap-4 items-center">

  <form class="grow p-8 bg-white" [formGroup]="registerForm" errorTailor (ngSubmit)="doRegister()">
    <h2 class="mb-8 flex justify-between items-center">Create Account <a class="cursor-pointer" (click)="dynamicDialogRef.close()">
      <i class="fa text-gray-500 fa-times"></i></a>
    </h2>
    @if (registerForm.controls['role'].value == 'customer') {
      <div class="form-group">
        <label for="" class="form-label">Enter Referral ID (optional)</label>
        <input type="text" formControlName="referred_by" (change)="checkUser()" class="form-control">
        @if (referer) {
          <span class="mt-1 text-sm text-green-600">Referrer Name: {{ referer.name }}</span>
        } @else if (refLoading) {
          <small>Checking...</small>
        } @else if (referer == 0 && registerForm.value['referred_by']) {
          <span class="text-sm text-red-500 mt-1">Invalid Referrer Id</span>
        }
      </div>


    } @else {
      <div class="form-group nm-label mb-4">
        <label for="" class="form-label">Business Type</label>
        <div class="form-group">
          <label for="" class="form-label"></label>
          <input type="radio" formControlName="business_type" class="form-control" [controlErrorAnchor]="anchorRadio">
        </div>
        <div class="flex flex-column gap-4 pt-2">
          <div *ngFor="let category of ['Online','Offline' ,'Both']" class="flex items-center">
            <p-radioButton [value]="category" formControlName="business_type"
                           controlErrorsIgnore
                           [id]="'bt-'+category"
                           (onClick)="typeUpdated()"
                           [inputId]="'btx-'+category">
            </p-radioButton>
            <label [for]="'btx-'+category" class="ml-2 mb-0">{{ category }}</label>
          </div>
        </div>
        <ng-template controlErrorAnchor #anchorRadio="controlErrorAnchor"></ng-template>
      </div>
      @if (registerForm.contains('admin_discount')) {
        <div class="form-group">
          <label for="" class="form-label">Admin Discount %</label>
          <input type="text" pKeyFilter="num" formControlName="admin_discount" class="form-control" [controlErrors]="{'max':'Should be less that 100%'}">
        </div>
      }
      <div class="form-group">
        <label for="" class="form-label">Form Number</label>
        <input type="text" formControlName="form_number" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Declaration</label>
        <p-fileUpload mode="basic" [auto]="true" url="/api/upload" (onUpload)="onUpload($event)" name="file"></p-fileUpload>
        @if (registerForm.value.declaration) {
          <div class="py-1 px-2 bg-green-100 mt-2"> File Uploaded</div>
        }
      </div>
      <div class="form-group">
        <label for="" class="form-label">Dealing In</label>
        <p-multiSelect [display]="'chip'" formControlName="shop_categories" appendTo="body" styleClass="w-full border" [options]="shopCategories"></p-multiSelect>
      </div>
    }
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

      <div class="form-group col-span-2">
        <label for="" class="form-label">Name</label>
        <input type="text" formControlName="name" class="form-control">
      </div>
      @if (registerForm.controls['role'].value == 'customer') {
        <!--        <div class="form-group">-->
          <!--          <label for="" class="form-label">Date Of Birth</label>-->
          <!--          <div class="relative">-->
          <!--            <div class="form-group">-->
          <!--              <input type="text" formControlName="date_of_birth" class="form-control">-->
          <!--            </div>-->
          <!--            <div class="absolute right-0 top-0 left-0 right-0 opacity-0">-->
          <!--              <p-calendar appendTo="body"-->
          <!--                          dateFormat="d M yy"-->
          <!--                          [(ngModel)]="dob"-->
          <!--                          [ngModelOptions]="{standalone: true}"-->
          <!--                          [dataType]="'string'"-->
          <!--                          styleClass="form-control w-full"-->
          <!--                          class="form-control"-->
          <!--                          (ngModelChange)="calculateAge($event)">-->
          <!--              </p-calendar>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--        </div>-->
      } @else {
        <div class="form-group">
          <label for="" class="form-label">Business Name</label>
          <input type="text" formControlName="business_name" class="form-control">
        </div>
      }
      <div class="form-group">
        <label for="" class="form-label">Email</label>
        <input type="text" formControlName="email" (blur)="checkUnique('email')" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Mobile</label>
        <input type="text" formControlName="mobile"
               (blur)="checkUnique('mobile')"
               pKeyFilter="int" minlength="10" maxlength="10" class="form-control"
               [controlErrors]="{minlength:'Invalid Mobile Number',maxlength:'Invalid Mobile Number'}"
        >
      </div>
      <div class="form-group">
        <label for="" class="form-label">Password</label>
        <input type="password" formControlName="password" class="form-control">
      </div>
      <div class="form-group">
        <label for="" class="form-label">Confirm Password</label>
        <input type="password" formControlName="confirm_password" class="form-control">
      </div>
    </div>
    <app-loading-btn [loading]="loading">Register</app-loading-btn>
    <div class="mt-8">
      <a class="py-2 block" (click)="loginNow()">Already have an account? <span
        class="text-green-800 font-bold">Login Here</span></a>
    </div>
  </form>
</div>
