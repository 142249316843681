<router-outlet></router-outlet>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<p-toast position="bottom-center" key="affirm" [baseZIndex]="5000" styleClass="affirm-toast">
  <ng-template let-message pTemplate="headless">
    <div class=" bg-blue-950 p-3 shadow-none text-sm text-yellow-400">
      <i class="fa-regular text-teal-400 mr-2 fa-circle-check"></i>{{ message.summary }}
    </div>
  </ng-template>
</p-toast>
