import {Component} from '@angular/core';
import {RouterLink} from "@angular/router";
import {AuthService} from "../../xstudioz/services/auth.service";

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent {
  constructor(public authService: AuthService) {
  }
}
