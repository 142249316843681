import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../../xstudioz/services/auth.service";
import {inject} from "@angular/core";
import {NgEventBus} from "ng-event-bus";
import {SHOW_LOGIN} from "../../app.events";

export const loggedInGuard: CanActivateFn = (route, state) => {
  let authService = inject(AuthService);
  let eventBus = inject(NgEventBus);
  let router = inject(Router);
  if (authService.loggedIn()) return true;

  router.navigate(['/']).then(res => {
    eventBus.cast(SHOW_LOGIN);
  })
  return false;
};
