<div class="h-screen flex items-center justify-center">
  <div class="text-center max-w-[600px] m-4">
    <img class="inline-block mx-auto mb-8 w-[160px]" src="assets/logo-red.png" alt="">
    <h2 class="mb-4">Page Not Found</h2>
    <p>The page you are looking for does not exist or you dont have the permission to view it.</p>


    <div class="flex gap-4 justify-center">
      <a class="px-4 py-2 rounded bg-red-700 text-white inline-block mt-6" routerLink="/">Homepage</a>

      @if (authService.user) {
        <a class="px-4 py-2 rounded bg-blue-700 text-white inline-block mt-6" routerLink="/dashboard">Dashboard</a>
      }
    </div>
  </div>
</div>
