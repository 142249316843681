<ul class="grow flex items-center text-white header-menu">
  <li>
    <app-header-cart></app-header-cart>
  </li>
  @if (authService.user) {
    <!--    @if (authService.admin()) {-->
      <!--      <li><a (click)="openVendorRegister()"> Vendor Register</a></li>-->
      <!--    }-->
    <li><a routerLink="shop/wishlist"><img src="assets/heart.svg" class="size-6" alt="">Wishlist</a></li>
    <li class="relative">
      <a (click)="op.show($event)">
        <app-resized-image classList="rounded-full shadow size-6" [src]="authService.user.image" [width]="24" aspectRatio="1-1"></app-resized-image>
        @if (authService.loggedIn()) {
          {{ authService.user?.business_name ?? authService.user?.name }}
        } @else {
          <div>Account</div>
        }
      </a>
    </li>
  } @else {

    <li><a (click)="openLogin()"><img src="assets/user.svg" class="size-6" alt="">Login/Register</a></li>
  }
</ul>

<p-overlayPanel #op>
  <ul class="sub-menu">
    <li><a class="py-2 block border-b" [routerLink]="['/dashboard']">Dashboard</a></li>
    <li><a class="py-2 block " (click)="logout()">Logout</a></li>
  </ul>
</p-overlayPanel>
