import {Component} from '@angular/core';
import {ImagePreviewComponent} from "../../image-preview/image-preview.component";
import {AuthService} from "../../../xstudioz/services/auth.service";
import {NgEventBus} from "ng-event-bus";
import {Router, RouterLink} from "@angular/router";
import {HeaderCartComponent} from "../header-cart/header-cart.component";
import {ResizedImageComponent} from "../../../xstudioz/components/resized-image.component";
import {SHOW_LOGIN} from "../../../app.events";
import {OverlayPanelModule} from "primeng/overlaypanel";

@Component({
  selector: 'app-header-menu',
  standalone: true,
  imports: [
    ImagePreviewComponent,
    RouterLink,
    HeaderCartComponent,
    ResizedImageComponent,
    OverlayPanelModule
  ],
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.scss'
})
export class HeaderMenuComponent {
  constructor(public authService: AuthService, public router: Router, public eventBus: NgEventBus) {
  }

  openLogin() {
    this.eventBus.cast(SHOW_LOGIN, {role: 'seller'})
  }

  openVendorRegister() {
    this.eventBus.cast('app:show-register', {role: 'seller'})
  }

  logout() {
    this.authService.logout().subscribe(res => {
      this.router.navigate(['/']).then()
    });
  }
}
