<div class="flex gap-4 items-center">
  <div class="max-w-[400px] p-8">
    <img class="w-48" src="assets/logo-red.png" alt="">
    <h3 class="mt-8">Welcome to <span class="text-red-500">Coromin infotech</span> <br>and network private limited
    </h3>
  </div>


  @if (!resetToken) {
    <form #myForm class="grow p-8 bg-white" [formGroup]="forgotPasswordForm" errorTailor (ngSubmit)="doReset(myForm)">
      <h2 class="mb-8 flex justify-between items-center">Reset Password <a class="cursor-pointer" (click)="dynamicDialogRef.close()"><i class="fa text-gray-500 fa-times"></i></a></h2>
      @if (error) {
        <div class="alert alert-danger"><i class="fa mr-2 fa-times-circle"></i>{{ error }}</div>
      }
      @if (reset) {
        <div class="alert alert-success"><i class="fa mr-2 fa-check-circle"></i>Please check your email for password reset link</div>
      }
      <p>Enter your unique id to receive a link to reset your password</p>
      <div class="form-group mt-4">
        <label for="" class="form-label">Unique ID</label>
        <input type="text" formControlName="unique_id" class="form-control">
      </div>

      <app-loading-btn [loading]="loading">Reset Password</app-loading-btn>
      <div class="mt-8">
        <a (click)="showLogin()" class="py-2 block cursor-pointer hover:text-gray-800">Back to Login</a>
        <a (click)="register()" class="py-2 block cursor-pointer hover:text-gray-800">Dont have an account? <span
          class="text-green-800 font-bold">Register Now</span></a>
      </div>
    </form>
  } @else {
    <form class="grow p-8 bg-white" [formGroup]="newPassword" errorTailor (ngSubmit)="setNewPassword()" controlErrorsIgnore>
      <h2 class="mb-8 flex justify-between items-center">Reset Password <a class="cursor-pointer" (click)="dynamicDialogRef.close()">
        <i class="fa text-gray-500 fa-times"></i></a>
      </h2>
      @if (error) {
        <div class="alert alert-danger"><i class="fa fa-times-circle"></i>{{ error }}</div>
      }
      @if (reset) {
        <div class="alert alert-success"><i class="fa fa-check-circle"></i>Please check your email for password reset link</div>
      }

      <div class="form-group mt-4">
        <label for="" class="form-label">Password</label>
        <input type="password" formControlName="password" class="form-control">
      </div>
      <div class="form-group mt-4">
        <label for="" class="form-label">Confirm Password</label>
        <input type="password" formControlName="confirm_password" class="form-control">
      </div>
      <app-loading-btn [loading]="loading">Set New Password</app-loading-btn>
      <div class="mt-8">
        <a (click)="showLogin()" class="py-2 block cursor-pointer hover:text-gray-800">Back to Login</a>
        <a (click)="register()" class="py-2 block cursor-pointer hover:text-gray-800">Dont have an account? <span
          class="text-green-800 font-bold">Register Now</span></a>
      </div>
    </form>
  }
</div>

