import {Component, inject} from '@angular/core';
import {RouterLink} from "@angular/router";
import {ApiService} from "../../xstudioz/services/api.service";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  public api = inject(ApiService);
  public count = 0;

  constructor() {
    this.visitorCount();
  }

  visitorCount() {
    this.api.get('get-visitor').subscribe((res: any) => {
      this.count = res.count;
    })
  }
}
