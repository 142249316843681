import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {JsonPipe, NgClass} from "@angular/common";

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    JsonPipe,
    NgClass
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss'
})
export class ContactComponent {
  public contact;
  public social = ['facebook', 'twitter', 'youtube', 'instagram', 'telegram', 'whatsapp'];

  constructor(public activatedRoute: ActivatedRoute) {
    this.activatedRoute.data.subscribe(res => {
      this.contact = res;
    })
  }
}
