<app-header [fullWidth]="true"></app-header>
<div class="flex pt-[120px]">
  <div class="sidebar-menu" [ngClass]="{'menu-open':menuOpened}" (click)="menuOpened =!menuOpened">

    <ul>
      <li class="bg-yellow-500 font-bold uppercase">
        <a routerLink="/">Shopping</a>
      </li>
    </ul>
    @if (authService.admin()) {
      <ul>
        <li><a routerLink="shop/shipping-address">Update Shipping Address</a></li>
        <li><a routerLink="shop/attributes">Manage Attributes</a></li>
        <li><a routerLink="shop/products">Manage Products</a></li>
        <li><a routerLink="shop/orders">Manage Orders</a></li>
      </ul>
    }

    @if (!authService.upgraded() && !authService.seller()) {
      <ul>
        <li><a [routerLink]="['/dashboard']"><i class=" fa-solid fa-dashboard"></i>Dashboard</a></li>
        <li><a [routerLink]="['/dashboard/my-profile']"><i class="fa fa-user"></i>Edit Profile</a></li>
        <li><a [routerLink]="['/dashboard/change-password']"><i class="fa-solid fa-building-columns"></i> Change Password</a></li>
        <li><a [routerLink]="['/dashboard/recharges']"><i class="fa-solid fa-building-columns"></i> Recharges</a></li>
        <li><a [routerLink]="['/dashboard/wallet-request']"><i class="fa-brands fa-google-wallet"></i> Wallet Request</a></li>
        <li><a [routerLink]="['/dashboard/upgrade-account']"><i class="fa-solid fa-circle-up"></i> Upgrade Account</a></li>
      </ul>
      @if (!authService.seller()) {
        <ul class="bg-blue-50">
          <li class="py-1 text-sm uppercase bg-amber-600 text-center text-white">EMART</li>
          <li><a [routerLink]="['/dashboard/offline-vendor']"><i class="fa-solid fa-building-columns"></i>Seller List</a></li>
          <li><a [routerLink]="['/dashboard/my-orders']"><i class="fa-solid fa-building-columns"></i>My Orders</a></li>
          <li><a [routerLink]="['/dashboard/pay-to-vendor']"><i class="fa-brands fa-google-wallet"></i> Pay To Seller</a></li>
        </ul>
      }

      <ul class="bg-green-50">
        @if (!authService.seller()) {
          <li class="py-1 bg-green-600 text-white text-center uppercase text-sm">My Wallets</li>
          <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'shopping'}"><i class="fa-regular fa-money-bill-1"></i> Shopping Wallet</a></li>
        } @else {
          <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'earning'}"><i class="fa-regular fa-money-bill-1"></i> My Wallet</a></li>
        }
      </ul>

      <ul>

        <li><a [routerLink]="['/dashboard/tickets/']"><i class="fa-regular fa-money-bill-1"></i> Tickets</a></li>
        <li><a class="cursor-pointer" (click)="logout()"><i class="fa fa-power-off"></i>Logout</a></li>


      </ul>
    } @else {
      @if (authService.user.status == 'active') {
        <div *appUpgraded="true" class="p-4 bg-green-600 text-white text-center">Premium Account</div>
        <ul>
          <li class="bg-blue-950 text-white py-1 text-center text-sm">Profile</li>
          <li><a [routerLink]="['/dashboard']"><i class=" fa-solid fa-dashboard"></i>Dashboard</a></li>
          <li><a [routerLink]="['/dashboard/my-profile']"><i class="fa fa-user"></i>Edit Profile</a></li>
          <li><a [routerLink]="['/dashboard/change-password']"><i class="fa-solid fa-building-columns"></i> Change Password</a></li>
        </ul>
        <ul>
          <li><a [routerLink]="['/dashboard/recharges']"><i class="fa-solid fa-building-columns"></i> Recharges</a></li>


          @if (!authService.seller()) {
            <li *appUpgraded="false"><a [routerLink]="[UPGRADE_ACCOUNT]"><i class="fa-solid fa-building-columns"></i> Upgrade Account</a></li>
            <li><a [routerLink]="['/dashboard/wallet-request']"><i class="fa-brands fa-google-wallet"></i> Wallet Request</a></li>
            <li><a [routerLink]="['/dashboard/upgrade-user-account']"><i class="fa fa-arrow-up"></i>Upgrade User Account</a></li>
          }
        </ul>
        @if (!authService.seller()) {
          <div class="bg-teal-100">
            <div (click)="checkTrxPassword()" class="uppercase p-2 text-sm bg-teal-500 flex items-center justify-between">Affiliate Section <i [ngClass]="{'fa-lock':trxPassword!=1,'fa-unlock':trxPassword==1}" class="fa fa-lock"></i></div>
            @if (trxPassword == 1) {
              <ul>
                <li><a [routerLink]="['/dashboard/aff-dashboard']"><i class=" fa-solid fa-dashboard"></i>Affiliate Dashboard</a></li>
                <li><a [routerLink]="['/dashboard/kyc']"><i class="fa-solid fa-building-columns"></i> KYC</a></li>
                <li><a [routerLink]="['/dashboard/bank-accounts']"><i class="fa-solid fa-building-columns"></i> My Bank Accounts</a></li>
                <li class="py-1 text-sm uppercase bg-amber-600 text-center text-white">Reports</li>
                <li><a [routerLink]="['/dashboard/autopool-tree']"><i class="fa-solid fa-building-columns"></i> Autopool Tree</a></li>
                <li><a [routerLink]="['/dashboard/downline-tree']"><i class="fa-solid fa-building-columns"></i> Downline Tree</a></li>
                <li><a [routerLink]="['/dashboard/downline-team']"><i class="fa-solid fa-building-columns"></i> Downline Team</a></li>
                <li><a [routerLink]="['/dashboard/referral-team']"><i class="fa-solid fa-building-columns"></i> Referral Teams</a></li>
                <li class="py-1 text-sm uppercase bg-amber-600 text-center text-white">Wallets</li>
                <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'earning'}"><i class="fa-regular fa-money-bill-1"></i> Earning Wallet</a></li>
                <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'discount'}"><i class="fa-regular fa-money-bill-1"></i> Discount Wallet</a></li>
                <li><a [routerLink]="['/dashboard/bank-transfers/']" [queryParams]="{type:'earning'}"><i class="fa-regular fa-money-bill-1"></i> Withdrawal Requests</a></li>
              </ul>
            }
          </div>

        }


        @if (!authService.seller()) {
          <ul>
            <li class="py-1 text-sm uppercase bg-amber-600 text-center text-white">EMART</li>
            <li><a [routerLink]="['/dashboard/offline-vendor']"><i class="fa-solid fa-building-columns"></i>Seller List</a></li>
            <li><a [routerLink]="['/dashboard/my-orders']"><i class="fa-solid fa-building-columns"></i>My Orders</a></li>
            <li><a [routerLink]="['/dashboard/pay-to-vendor']"><i class="fa-brands fa-google-wallet"></i> Pay To Seller</a></li>
          </ul>
        }

        <ul>
          @if (!authService.seller()) {
            <li class="py-1 bg-green-600 text-white text-center uppercase text-sm">My Wallets</li>
            <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'shopping'}"><i class="fa-regular fa-money-bill-1"></i> Shopping Wallet</a></li>

          } @else {
            <li><a [routerLink]="['/dashboard/wallets/']" [queryParams]="{type:'earning'}"><i class="fa-regular fa-money-bill-1"></i> My Wallet</a></li>
            <li><a [routerLink]="['/dashboard/receive-payment']"><i class="fa-solid fa-circle-up"></i> Receive Payment</a></li>

          }
        </ul>

        <ul *appRole="['admin']">
          <li class="bg-purple-600 text-center py-1 text-sm text-white">ADMIN Settings</li>
          <li><a (click)="sellerBox()"><i class="fa fa-arrow-up"></i>Create Seller</a></li>
          <li><a [routerLink]="['/dashboard/admin/contact-settings']"><i class="fa fa-arrow-up"></i>Contact Settings</a></li>
          <li><a [routerLink]="['/dashboard/admin/package-settings']"><i class="fa fa-arrow-up"></i>Package Settings</a></li>
          <li><a [routerLink]="['/dashboard/admin/upgrade-user-account']"><i class="fa fa-arrow-up"></i>Upgrade User Account</a></li>
          <li><a [routerLink]="['/dashboard/admin/commission-settings']"><i class="fa fa-gear"></i>Referral Commission</a></li>
          <li><a [routerLink]="['/dashboard/admin/tds-settings']"><i class="fa fa-gear"></i>Admin/TDS Commission</a></li>
          <li class="bg-lime-600 text-center py-1 text-sm text-white">Manage Data</li>
          <li><a [routerLink]="['/dashboard/admin/wallet-requests']"><i class="fa fa-gear"></i>Manage Wallet Requests</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-banks']"><i class="fa fa-gear"></i>Manage Bank Accounts</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-kyc/customer']"><i class="fa fa-gear"></i>Manage Customer KYC's</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-kyc/seller']"><i class="fa fa-gear"></i>Manage Seller KYC's</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-users']"><i class="fa fa-gear"></i>Manage Users</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-tickets']"><i class="fa fa-gear"></i>Manage Tickets</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-shop-categories']"><i class="fa fa-gear"></i>Manage Shop Categories</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-bank-transfers']"><i class="fa fa-gear"></i>Manage Bank Transfers</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-news']"><i class="fa fa-gear"></i>Manage News</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-pages']"><i class="fa fa-gear"></i>Manage Pages</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-recharges']"><i class="fa fa-gear"></i>Manage Recharges</a></li>
          <li><a [routerLink]="['/dashboard/admin/manage-homepage']"><i class="fa fa-gear"></i>Manage Homepage</a></li>

        </ul>
      }

      <ul>
        <li><a [routerLink]="['/dashboard/tickets/']"><i class="fa-regular fa-money-bill-1"></i> Tickets</a></li>
        <li><a class="cursor-pointer" (click)="logout()"><i class="fa fa-power-off"></i>Logout</a></li>
      </ul>
    }

  </div>
  <div class="dashboard-content">
    <div class=" p-6 ">
      @if (!authService.active()) {
        <div class="alert alert-danger bg-red-200">You account is inactive. Please raise a ticket to get it activated</div>
      }
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<app-dashboard-footer></app-dashboard-footer>
