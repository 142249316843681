@if (submitted) {
  <div class="prose p-8 max-w-none">
    <div class="flex justify-between mb-8">
      <h2 class="p-0 m-0">Thank you</h2>
      <button (click)="dynamicDialogRef.close()"><i class="fa fa-times"></i></button>
    </div>
    Thank you for submitting your inquiry! Your request has been received. We will get back to you as soon as possible with further details. If you have any urgent questions or concerns, feel free to contact us directly. We appreciate your patience and look forward to assisting you further.
  </div>

} @else {

  <form [formGroup]="sellerEnquiryForm" errorTailor (ngSubmit)="submit()">
    <div class="md:grid grid-cols-3 gap-4 items-center">
      <div class="hidden md:block max-w-[400px] col-span-1 prose text-left shrink-0 p-6">
        <img src="assets/vendor.png" alt="" class="block mx-auto ">
      </div>
      <div class="col-span-2 bg-white  p-8 prose">
        <div class="flex justify-between mb-8">
          <h2 class="p-0 m-0">Become a Seller</h2>
          <button type="button" (click)="dynamicDialogRef.close()"><i class="fa fa-times"></i></button>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Name</label>
          <input type="text" formControlName="name" class="form-control">
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="form-group">
            <label for="" class="form-label">Email</label>
            <input type="text" formControlName="email" class="form-control">
          </div>
          <div class="form-group">
            <label for="" class="form-label">Phone</label>
            <input type="text" formControlName="phone" class="form-control">
          </div>
        </div>
        <div class="form-group">
          <label for="" class="form-label">Business Name</label>
          <input type="text" formControlName="business_name" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Address</label>
          <input type="text" formControlName="address" class="form-control">
        </div>
        <div class="form-group">
          <label for="" class="form-label">Message</label>
          <textarea formControlName="message" class="form-control"></textarea>
        </div>
        <app-loading-btn [loading]="loading" classList="w-full bg-green-600 text-white p-4">Submit</app-loading-btn>
      </div>
    </div>

  </form>
}
