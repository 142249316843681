import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivatedRoute, Router, RouterOutlet, Scroll} from '@angular/router';
import {HeaderComponent} from "./common-templates/header/header.component";
import {FooterComponent} from "./common-templates/footer/footer.component";
import {LoginDialogComponent} from "./common-templates/dialogs/login-dialog/login-dialog.component";
import {MetaData, NgEventBus} from "ng-event-bus";
import {DialogService} from "primeng/dynamicdialog";
import {LocalStorage} from "ngx-webstorage";
import {RegisterDialogComponent} from "./common-templates/dialogs/register-dialog/register-dialog.component";
import {ApiService} from "./xstudioz/services/api.service";
import {ClientOnly} from "./xstudioz/decorators/run-on-client";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {ToastModule} from "primeng/toast";
import {ForgotPasswordComponent} from "./common-templates/dialogs/forgot-password/forgot-password.component";
import {ConfirmationService, MessageService, PrimeNGConfig} from "primeng/api";
import {AuthService} from "./xstudioz/services/auth.service";
import {AppSettingsService} from "./shared/services/app-settings.service";
import {CartService} from "./shared/services/cart.service";
import {SHOW_LOGIN} from "./app.events";
import {LoginFormComponent} from "./common-templates/dialogs/login-dialog/login-form/login-form.component";
import {CookieService} from "ngx-cookie-service";

function getImage(src: string) {
  return 'http://localhost:800' + src.replace('public/', 'storage/');
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent, LoginDialogComponent, ConfirmDialogModule, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  static webUrl = 'http://localhost:800'
  title = 'tabdeal-frontend';
  @Input() action;
  @Input() token;
  @LocalStorage() user: any;

  constructor(
    public router: Router,
    public cookieService: CookieService,
    public authService: AuthService,
    public eventBus: NgEventBus,
    public confirmationService: ConfirmationService,
    public dialogService: DialogService,
    public messageService: MessageService,
    public activatedRoute: ActivatedRoute,
    public cartService: CartService,
    public primeNGConfig: PrimeNGConfig,
    public api: ApiService) {
    this.registerGlobalEvents();

    this.primeNGConfig.zIndex = {
      modal: 1100,    // dialog, sidebar
      overlay: 1000,  // dropdown, overlaypanel
      menu: 1000,     // overlay menus
      tooltip: 1100   // tooltip
    };
    this.addVisit(); //
  }

  addVisit() {
    this.api.post('add-visitor', {}).subscribe(res => {
      //this.cookieService.set('tabdeal_visitor', {expires: 2})
    });
  }

  ngOnInit(): void {
    if (this.action == 'set-new-password') {
      this.eventBus.cast('app:show-forgot-password', {token: this.token});
    }
    this.activatedRoute.queryParams.subscribe((res: any) => {
      if (!res.ref) return;
      let data = {ref: res.ref};
      this.eventBus.cast('app:show-register', data)
    })

  }


  // @ClientOnly()
  // csrfCookie() {
  //   this.api.get('csrf-cookie').subscribe();
  // }

  @ClientOnly()
  registerGlobalEvents() {
    this.eventBus.on(SHOW_LOGIN).subscribe((res: MetaData) => {
      this.dialogService.open(LoginFormComponent, {showHeader: false, styleClass: 'login-form', data: res.data})
    });
    this.eventBus.on('app:show-register').subscribe((res: MetaData) => {
      //console.log('Data from ventbus', res);
      this.dialogService.open(RegisterDialogComponent, {
        showHeader: false,
        styleClass: 'login-form',
        data: {
          role: res.data?.role ?? 'customer',
          ref: res.data?.ref ?? null
        }
      })
    })
    this.eventBus.on('app:logout').subscribe((res: MetaData) => {
      this.authService.logout().subscribe(res => {
        this.router.navigate(['/']).then();
        this.messageService.add({severity: 'success', summary: 'Success', detail: 'Logged out successfully'});
      });
    })
    this.eventBus.on('app:show-forgot-password').subscribe((res: MetaData) => {
      let data = {
        role: res.data?.role ?? 'customer',
      };
      if (res.data?.token) {
        data['token'] = res.data.token
      }
      this.dialogService.open(ForgotPasswordComponent, {showHeader: false, styleClass: 'login-form', data: data})
    })
  }
}
