import {ResolveFn} from '@angular/router';
import {first} from "rxjs";
import {inject} from "@angular/core";
import {ApiService} from "../../xstudioz/services/api.service";

export const contactResolver: ResolveFn<any> = (route, state) => {
  let api = inject(ApiService);
  return api.get('settings/contact-page')
    .pipe(first(),)
};
