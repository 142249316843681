import {Component, inject} from '@angular/core';
import {Router, RouterLink, RouterOutlet} from "@angular/router";
import {DashboardHeaderComponent} from "../common-templates/dashboard-header/dashboard-header.component";
import {DashboardFooterComponent} from "../common-templates/dashboard-footer/dashboard-footer.component";
import {HeaderComponent} from "../common-templates/header/header.component";
import {RoleDirective} from "../xstudioz/directives/app-role";
import {AuthService} from "../xstudioz/services/auth.service";
import {UpgradedDirective} from "../shared/upgraded.directive";
import {BlockUIModule} from "primeng/blockui";
import {NgEventBus} from "ng-event-bus";
import {NgClass} from "@angular/common";
import {LocalStorage, SessionStorage} from "ngx-webstorage";
import {routes} from "../app.routes";
import {UPGRADE_ACCOUNT} from "../routes";
import {DialogService} from "primeng/dynamicdialog";
import {CheckTrxPasswordComponent} from "./affiliate-section/check-trx-password/check-trx-password.component";
import {MessageService} from "primeng/api";
import {ApiService} from "../xstudioz/services/api.service";
import {finalize} from "rxjs";
import {SetTrxPasswordComponent} from "./affiliate-section/set-trx-password/set-trx-password.component";
import {RegisterDialogComponent} from "../common-templates/dialogs/register-dialog/register-dialog.component";


@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    RouterOutlet,
    DashboardHeaderComponent,
    DashboardFooterComponent,
    RouterLink,
    HeaderComponent,
    RoleDirective,
    UpgradedDirective,
    BlockUIModule,
    NgClass
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export default class DashboardComponent {
  authService = inject(AuthService);
  api = inject(ApiService);
  public loading = false;
  @SessionStorage() trxPassword;
  @LocalStorage()
  public menuOpened;

  constructor(public router: Router, public eventBus: NgEventBus, public dialogService: DialogService, public messageService: MessageService) {
    this.eventBus.on('app:open-menu').subscribe(() => {
      this.menuOpened = !this.menuOpened;
    })
  }

  logout() {
    this.authService.logout().subscribe(res => {
      this.router.navigate(['/']).then();
    });
  }

  protected readonly routes = routes;
  protected readonly UPGRADE_ACCOUNT = UPGRADE_ACCOUNT;

  sellerBox() {
    this.dialogService.open(RegisterDialogComponent, {header: 'Register Seller', data: {role: 'seller'}});
  }

  checkTrxPassword() {


    if (!this.trxPassword) {
      this.api.post('user/has-trx-password', {})
        .pipe(finalize(() => this.loading = false))
        .subscribe((res: any) => {
          if (res.status == false) {
            this.dialogService.open(SetTrxPasswordComponent, {header: 'Set Affiliate Password', styleClass: 'dialog-form'}).onClose.subscribe(r => {
              if (r) this.messageService.add({key: 'affirm', summary: 'Affiliate section has been unlocked'});
            })
          } else {
            this.dialogService.open(CheckTrxPasswordComponent, {header: 'Enter Affiliate Password', styleClass: 'dialog-form'}).onClose.subscribe(r => {
              if (r) this.messageService.add({key: 'affirm', summary: 'Affiliate section has been unlocked'});
            })
          }
        })


    }
  }
}
