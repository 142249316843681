import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {errorTailorImports, FormActionDirective} from "@ngneat/error-tailor";
import {SendOtpComponent} from "../../../shared/send-otp.component";
import {ApiService} from "../../../xstudioz/services/api.service";
import {finalize} from "rxjs";
import {matchValidator} from "../../../xstudioz/validators/match-password";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {LoadingBtnComponent} from "../../../xstudioz/components/loading-btn.component";

@Component({
  selector: 'app-set-trx-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormActionDirective,
    SendOtpComponent,
    errorTailorImports,
    LoadingBtnComponent
  ],
  templateUrl: './set-trx-password.component.html',
  styleUrl: './set-trx-password.component.scss'
})
export class SetTrxPasswordComponent {
  public loading = false;
  public api = inject(ApiService)
  public dynamicDialogRef = inject(DynamicDialogRef)

  public form = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirm_password: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.required]),
  }, [matchValidator('password', 'confirm_password')])

  submit() {
    this.loading = true;
    this.api.post('user/set-trx-password', this.form.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe((res: any) => {
        this.dynamicDialogRef.close(true);
      })
  }
}
