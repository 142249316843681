import {ResolveFn} from '@angular/router';
import {ApiService} from "../../xstudioz/services/api.service";
import {inject} from "@angular/core";
import {first, Observable, tap} from "rxjs";
import {ViewportScroller} from "@angular/common";

export const pageResolver: ResolveFn<Observable<any>> = (route, state) => {
  let api = inject(ApiService);
  let scroller = inject(ViewportScroller);
  return api.get('page/' + route.params['slug'], {})
    .pipe(first(), tap(() => {
      if (window) scroller.scrollToPosition([0, 0])
    }))

};
