import {Component, inject} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {errorTailorImports, FormActionDirective} from "@ngneat/error-tailor";
import {ApiService} from "../../../xstudioz/services/api.service";
import {finalize} from "rxjs";
import {LoadingBtnComponent} from "../../../xstudioz/components/loading-btn.component";
import {SessionStorage} from "ngx-webstorage";
import {DialogService, DynamicDialogRef} from "primeng/dynamicdialog";
import {SetTrxPasswordComponent} from "../set-trx-password/set-trx-password.component";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-check-trx-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormActionDirective,
    errorTailorImports,
    LoadingBtnComponent,
  ],
  templateUrl: './check-trx-password.component.html',
  styleUrl: './check-trx-password.component.scss'
})
export class CheckTrxPasswordComponent {
  public loading = false;
  public api = inject(ApiService)
  public dialogService = inject(DialogService)
  public messageService = inject(MessageService)
  public form = new FormGroup({
    password: new FormControl(null, [Validators.required])
  })
  @SessionStorage() trxPassword;

  constructor(public dynamicDialogRef: DynamicDialogRef) {
  }

  submit() {
    this.loading = true;
    this.api.post('user/check-trx-password', this.form.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe((res: any) => {
        if (res) {
          this.trxPassword = 1
          this.dynamicDialogRef.close(true);
        }
      })
  }

  reset() {
    this.dynamicDialogRef.close(false);
    this.dialogService.open(SetTrxPasswordComponent, {header: 'Set Affiliate Password', styleClass: 'dialog-form'}).onClose.subscribe(r => {
      if (r) this.messageService.add({key: 'affirm', summary: 'Affiliate Password has been updated'});
    })
  }
}
