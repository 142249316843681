import {Component, inject} from '@angular/core';
import {ApiService} from "../../../xstudioz/services/api.service";
import {Router, RouterLink} from "@angular/router";
import {finalize} from "rxjs";
import {ClientOnly} from "../../../xstudioz/decorators/run-on-client";
import {CartService} from "../../../shared/services/cart.service";
import {BadgeModule} from "primeng/badge";
import {SidebarModule} from "primeng/sidebar";
import {StyleClassModule} from "primeng/styleclass";
import {ResizedImageComponent} from "../../../xstudioz/components/resized-image.component";
import {PriceTagComponent} from "../../../front-end/shop-page/price-tag/price-tag.component";
import {CounterComponent} from "../../../xstudioz/components/counter.component";
import {ICartItem} from "../../../interfaces/ICartItem";
import {CurrencyPipe, NgIf} from "@angular/common";
import {VariationDataComponent} from "../../../shared/components/variation-data.component";

@Component({
  selector: 'app-header-cart',
  standalone: true,
  imports: [
    BadgeModule,
    SidebarModule,
    StyleClassModule,
    ResizedImageComponent,
    PriceTagComponent,
    CounterComponent,
    CurrencyPipe,
    RouterLink,
    NgIf,
    VariationDataComponent
  ],
  templateUrl: './header-cart.component.html',
  styleUrl: './header-cart.component.scss'
})
export class HeaderCartComponent {

  public sideCartVisible = false;

  constructor(public cartService: CartService) {

  }

  updateQty(item: ICartItem, $event: any) {
    this.cartService.addItem(item.product.id, $event, 'update').subscribe();
  }

  remove(prouductId: number) {
    this.cartService.removeItem(prouductId).subscribe();
  }

  navToCheckout() {
    this.sideCartVisible = false;
  }
}
